import React from "react"
import "./DesignerCard.scss"
import swal from "sweetalert"
import StarRating from "../StarRating/StarRating"
import { dottedText } from "../../utility/general"
import { designerSkills } from "../../constants/data/general"
import { Link } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import ReactTooltip from "react-tooltip"
const DesignerCard = React.memo(({ hit, bookmarked, onSelect }) => {
  const [toggleBookmark, setToggleBookmark] = React.useState(false)

  const showSkills = () => {
    try {
      return hit.skills.map((skill, index) => {
        let found = designerSkills.find(d => d.value === skill.value)
        if (found != -1) {
          return (
            <i
              key={index}
              style={{
                marginTop: "7px",
                // marginRight: "5px",
                // fontSize: "14px",
                color: "#fec600",
              }}
              data-tip={`${found.label}`}
              className={`${found.icon}`}
            ></i>
          )
        } else return ""
      })
    } catch (err) {}
  }
  const showHeart = params => {
    return toggleBookmark ? (
      <i
        className="fas fa-heart red-star"
        style={{ marginLeft: "5px" }}
        onClick={() => {
          setToggleBookmark(!toggleBookmark)
        }}
      ></i>
    ) : (
      <i
        className="far fa-heart red-star"
        style={{ marginLeft: "5px" }}
        onClick={() => {
          setToggleBookmark(!toggleBookmark)
        }}
      ></i>
    )
  }
  function transform(node) {
    // do not render any <span> tags
    if (node.type === "tag" && node.name === "br") {
      return null
    }
  }
  const showName = () => {
    let combinedName = hit.firstName + " " + hit.lastName
    return dottedText(combinedName, 15)
  }

  return (
    <React.Fragment>
      <ReactTooltip />
      <div
        //className="fade-in"
        className="designer-card-type2"
        style={
          {
            // marginBottom: "15px",
            // borderBottom: "1px solid #fec600",
            // padding: "10px",
            // height: "180px",
          }
        }
        onClick={params => {
          // window.open(`/app/userprofiles/${hit.objectID}`, "_blank")
        }}
      >
        <article
          className="media"
          style={{
            position: "relative",
          }}
        >
          <div
            className="media-left"
            style={{
              marginRight: "0px",
              position: "relative",
            }}
          >
            <figure
              className="image is-128x128"
              // style={{ width: "140px", height: "140px", borderRadius: "50%" }}
              onClick={params => {
                // window.open(`/app/userprofiles/${hit.objectID}`, "_blank")
              }}
            >
              <img
                className=""
                style={{
                  borderRadius: "0px",
                  objectFit: "cover",
                  height: "100%",
                  width: "100%",
                  // padding: "5px",
                }}
                src={hit.userProfileImage}
                alt="Image"
              ></img>
            </figure>
            <div
              style={{
                position: "absolute",
                width: "110px",
                bottom: "7px",
                left: "50%",
                transform: "translate(-50%)",
                opacity: "0.8px",
              }}
              // className="center"
            >
              <button
                onClick={() => {
                  window.open(`/app/userprofiles/${hit.objectID}`, "_blank")
                }}
                className="button is-white is-small"
                style={{
                  // marginRight: "10px",
                  letterSpacing: "0.5px",
                  fontSize: "11px",
                  textDecorationColor: "#fec600",
                  width: "100%",
                  opacity: "0.9",
                  // position:'absolute'
                }}
                // style={{ fontSize: "14px", fontWeight: "400" }}
              >
                View Profile
              </button>
            </div>
          </div>
          <div
            className="media-content"
            style={{ padding: "5px 10px", marginTop: "0px" }}
          >
            <div className="content" style={{ position: "" }}>
              <p>
                <a
                  className=""
                  style={{
                    // marginLeft: "10px",
                    // marginLeft: "10px",
                    fontSize: "14px",
                    fontWeight: "500",
                    // textDecoration: "underline",
                    textDecorationColor: "#fec600",
                    color: "rgba(74,74,74,0.8)",
                    marginTop: "5px",
                    letterSpacing: "0.5px",
                  }}
                  onClick={() => {
                    window.open(`/app/userprofiles/${hit.objectID}`, "_blank")
                  }}
                >
                  {showName()}
                </a>
                {/* <br></br> */}
                <p
                  style={{
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "rgba(74,74,74,0.5)",
                    // marginTop: "5px",
                    marginBottom: "0px",
                  }}
                >
                  <i
                    style={{ marginRight: "5px", color: "#fec600" }}
                    className="fas fa-map-marker-alt"
                  ></i>
                  {dottedText(hit.location, 20)}
                </p>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "rgba(74,74,74,0.8)",
                  }}
                >
                  {hit.reviewCount === 0 ? (
                    // <i>- No reviews yet -</i>
                    ""
                  ) : (
                    <span
                      style={{
                        // marginLeft: "10px",
                        marginRight: "5px",
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {"  " + hit.reviewRating}{" "}
                      <i
                        style={{
                          marginLeft: "2px",
                          marginRight: "5px",
                          fontSize: "12px",
                          color: "#fac600",
                        }}
                        className="fas fa-star"
                      ></i>
                      <span
                        style={{
                          fontSize: "10px",
                          letterSpacing: "0.5px",
                          // textTransform: "uppercase",
                          color: "rgba(74,74,74,0.6)",
                        }}
                      >
                        {hit.reviewCount} Review(s){" "}
                      </span>
                    </span>
                  )}
                </span>{" "}
                {/* <small>31m</small> */}
                {/* <div className="tags is-small">
                  {hit.languagesSpoken
                    ? hit.languagesSpoken.map(item => {
                        if (item)
                          return (
                            <span key={item.label} className="tag is-warning">
                              {item.label}
                            </span>
                          )
                      })
                    : "--"}
                </div> */}
                {/* <br></br> */}
                {/* <br></br> */}
                {/* <span
                  className="profile-info-text"
                  style={{
                    // marginLeft: "10px",
                    fontSize: "14px",
                    fontWeight: "500",
                    display: "flex",
                  }}
                >
                  {ReactHtmlParser(dottedText(hit.aboutIntroduction, 60), {
                    decodeEntities: false,
                    transform,
                  })}
                  <a
                    style={{ marginLeft: "10px" }}
                    href={`/app/userprofiles/${hit.objectID}`}
                    target="_blank"
                  >
                    {" "}
                    See more
                  </a>
                </span> */}
              </p>
              <div
                style={{
                  position: "absolute",
                  bottom: "40px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "146px",
                }}
              >
                {showSkills()}
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "7px",
                  width: "146px",
                }}
              >
                <button
                  onClick={() => {
                    if (onSelect) onSelect(hit)
                  }}
                  className="button is-warning is-small"
                  style={{
                    // marginLeft: "10px",
                    letterSpacing: "0.5px",
                    fontSize: "11px",
                    width: "100%",
                  }}
                >
                  Choose Designer
                </button>
              </div>
            </div>

            {/* <div className="field">
              <p className="control">
                <a
                  onClick={() => {
                    window.open(`/app/userprofiles/${hit.objectID}`, "_blank")
                  }}
                  className="link"
                  style={{ fontSize: "14px", fontWeight: "400" }}
                >
                  View Profile
                </a>
              </p>
            </div> */}
            {/* <br></br> */}
            {/* <div className="field">
              <p className="control">
                <button
                  onClick={() => {
                    if (onSelect) onSelect(hit)
                  }}
                  style={{ fontSize: "15px", fontWeight: "400" }}
                  className="button is-warning"
                >
                  Select Designer
                </button>
              </p>
            </div> */}
          </div>
        </article>
        {/* <div
          className="media-right is-hidden-touch is-hidden-tablet "
          style={{ maxWidth: "250px" }}
        > */}

        {/* </div> */}
        <div
          style={
            {
              // position: "absolute",
              // bottom: "10px",
              // right: "10px",
            }
          }
        >
          <nav className="level is-mobile">
            <div className="level-left"></div>
          </nav>
        </div>
      </div>
      {/* <div className="designer-card">
        <div className="designer-card-content">
          <div
            className="columns is-mobile"
            style={{ position: "relative", backgroundColor: "" }}
          >
            <div
              className="column is-3-desktop is-2-tablet is-3-mobile"
              style={{
                alignSelf: "center",
                // paddingLeft: "0px",
                // paddingTop: "0px",
                // paddingBottom: "0px",
              }}
            >
              <img
                className=""
                style={{
                  borderRadius: "5px",
                  width: "70%",
                  height: "70%",
                  // width: "56px",
                  // height: "56px",
                  objectFit: "cover",
                  // border: "0.5px solid #efefef",
                }}
                src={hit.userProfileImage}
                alt=""
              />
              {toggleBookmark ? (
                <i
                  className="fas fa-heart red-star"
                  style={{
                    float: "right",
                    position: "absolute",
                    top: "0px",
                    left: "5px",
                  }}
                  onClick={() => {
                    setToggleBookmark(!toggleBookmark)
                  }}
                ></i>
              ) : (
                <i
                  className="far fa-heart red-star"
                  style={{
                    float: "right",
                    position: "absolute",
                    top: "0px",
                    left: "5px",
                  }}
                  onClick={() => {
                    setToggleBookmark(!toggleBookmark)
                  }}
                ></i>
              )}
            </div>

            <div
              className="column is-9-desktop is-10-tablet is-9-mobile"
              style={{ alignSelf: "center" }}
            >
              <div
                className="columns is-mobile"
                style={{ position: "relative", backgroundColor: "" }}
              >
                <div
                  className="column is-8-desktop is-8-tablet  is-11-mobile"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    alignSelf: "flex-start",
                  }}
                >
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {hit.firstName} {hit.lastName}{" "}
                    {showHeart()}
                  </p>
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    {hit.location}
                  </p>

                  <div style={{ fontSize: "16px", marginTop: "5px" }}>
                    {hit.reviewRating}{" "}
                    <i
                      style={{ marginRight: "5px", fontSize: "16px" }}
                      className="fas fa-star red-star"
                    ></i>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        display: "inline",
                      }}
                    >
                      {" "}
                      {hit.reviewCount} reviews
                    </p>
                  </div>
                </div>
                <div
                  className="column is-4-desktop is-4-tablet is-1-mobile"
                  style={{
                    alignSelf: "flex-end",
                    paddingRight: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <button
                    onClick={() => {
                      if (onSelect) onSelect(hit)
                    }}
                    className="button is-warning is-pulled-right"
                  >
                    Select
                  </button>
                  <Link
                    to={`/app/userprofiles/${hit.objectID}`}
                    className="hover-link is-pulled-right is-small"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    View Profile
                  </Link>
                  <a
                    href={`/app/userprofiles/${hit.objectID}`}
                    className="hover-link is-pulled-right is-small"
                    target="_blank"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    View Profile
                  </a>
                </div>
              </div>

              <div
                className="columns is-mobile"
                style={{ position: "relative" }}
              >
                {" "}
                <div
                  className="column is-8-desktop is-8-tablet is-hidden-mobile"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    alignSelf: "flex-start",
                    paddingTop: "0px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {dottedText(hit.aboutIntroduction, 75)}
                    <a
                      href={`/app/userprofiles/${hit.objectID}`}
                      target="_blank"
                    >
                      {" "}
                      See more
                    </a>
                  </p>
                </div>
                <div
                  className="column is-hidden-desktop is-hidden-tablet is-hidden-mobile"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    alignSelf: "flex-start",
                    paddingTop: "0px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {dottedText(hit.aboutIntroduction, 35)}
                    <a
                      href={`/app/userprofiles/${hit.objectID}`}
                      target="_blank"
                    >
                      {" "}
                      See more
                    </a>
                  </p>
                </div>
                <div
                  className="column is-4-desktop is-4-tablet is-hidden-mobile"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    alignSelf: "center",
                  }}
                >
                  <a
                    onClick={() => {
                      window.open(
                        `/app/userprofiles/${hit.objectID}`,
                        "_blank"
                      )
                    }}
                    className="hover-link is-warning is-pulled-right is-small"
                  >
                    View Profile
                  </a>
                </div>
                <div
                  className="column is-hidden-desktop is-hidden-tablet is-12-mobile"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    alignSelf: "flex-start",
                  }}
                >
                  <a
                    onClick={() => {
                      window.open(
                        `/app/userprofiles/${hit.objectID}`,
                        "_blank"
                      )
                    }}
                    className="hover-link is-warning is-pulled-right is-small"
                    style={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    View Profile
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  )
})

export default DesignerCard
