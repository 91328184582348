import React, { useEffect } from "react"
import "./DesignerCard.scss"
import swal from "sweetalert"
import StarRating from "../StarRating/StarRating"
import { dottedText, titleCase } from "../../utility/general"
import { designerSkills } from "../../constants/data/general"
import { Link, navigate } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import ReactHtmlParser from "react-html-parser"
import ReactTooltip from "react-tooltip"
const DesignerCardNew = ({ hit, bookmarked, onSelect }) => {
  const [toggleBookmark, setToggleBookmark] = React.useState(false)
  useEffect(() => {
    // console.log(hit)
  }, [hit])
  const showHeart = params => {
    return toggleBookmark ? (
      <i
        className="fas fa-heart red-star"
        style={{ marginLeft: "5px" }}
        onClick={() => {
          setToggleBookmark(!toggleBookmark)
        }}
      ></i>
    ) : (
      <i
        className="far fa-heart red-star"
        style={{ marginLeft: "5px" }}
        onClick={() => {
          setToggleBookmark(!toggleBookmark)
        }}
      ></i>
    )
  }
  function transform(node) {
    // do not render any <span> tags
    if (node.type === "tag" && node.name === "br") {
      return null
    }
    if (node.type === "tag" && node.name === "strong") {
      return null
    }
  }
  const showSkills = () => {
    try {
      return hit.skills.map((skill, index) => {
        let found = designerSkills.find(d => d.value === skill.value)
        if (found != -1) {
          return (
            <>
              {" "}
              {index === 0 ? (
                <>
                  {" "}
                  <p
                    style={{
                      marginBottom: "0px",
                      fontWeight: "600",
                      textTransform: "sentenceCase",
                    }}
                  >
                    Specializes in:
                  </p>
                  {found.description.charAt(0).toUpperCase() +
                    found.description.slice(1)}
                  ,
                </>
              ) : index === 3 ? (
                <span>
                  ...{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontWeight: "500",
                      color: "#fec600",
                    }}
                    onClick={() => {
                      window.open(`/app/userprofiles/${hit.objectID}`, "_blank")
                    }}
                  >
                    Read more
                  </span>{" "}
                </span>
              ) : index > 3 ? (
                ""
              ) : index === hit.skills.length - 1 ? (
                `and ${found.description}.`
              ) : (
                `${found.description}, `
              )}{" "}
            </>
            //   <>
            //   {" "}
            //   {index === 0 ? (
            //     <>
            //       {" "}
            //       <p
            //         style={{
            //           marginBottom: "0px",
            //           fontWeight: "600",
            //           textTransform: "sentenceCase",
            //         }}
            //       >
            //         Specializes in:
            //       </p>
            //       {found.description.charAt(0).toUpperCase() +
            //         found.description.slice(1)}
            //       ,
            //     </>
            //   ) : index === hit.skills.length - 1 ? (
            //     `and ${found.description}.`
            //   ) : (
            //     `${found.description}, `
            //   )}{" "}
            // </>
            // <i
            //   style={{
            //     marginTop: "7px",
            //     // marginRight: "5px",
            //     // fontSize: "14px",
            //     color: "#fec600",
            //   }}
            //   data-tip={`${found.label}`}
            //   className={`${found.icon}`}
            // ></i>
          )
        } else return ""
      })
    } catch (err) {}
  }
  const showCountries = () => {
    try {
      return (
        <div className="tags">
          {hit.countriesForItineraries
            ? hit.countriesForItineraries.map((item, index) => {
                if (item)
                  return (
                    <>
                      {index > 5 ? (
                        ""
                      ) : index === 5 ? (
                        <span
                          key={item.value}
                          className="tag is-small is-white"
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            background: "transparent",
                          }}
                          onClick={() => {
                            window.open(
                              `/app/userprofiles/${hit.objectID}`,
                              "_blank"
                            )
                          }}
                        >
                          + {hit.countriesForItineraries.length - 5} More
                        </span>
                      ) : (
                        <span
                          key={item.value}
                          className="tag is-small is-warning"
                        >
                          {item.value}{" "}
                          <span
                            style={{
                              color: "#fec600",
                              // marginLeft: "20px",
                            }}
                          >
                            .
                          </span>
                        </span>
                      )}
                    </>
                  )
              })
            : "--"}
        </div>
      )
    } catch (err) {}
  }
  const showName = () => {
    let combinedName = hit.firstName + " " + hit.lastName
    return dottedText(titleCase(combinedName, 20))
  }
  const showLocation = () => {
    // if (hit.location.includes("United States of America")) {
    //   hit.location.replace("United States of America", "USA")
    // }
    return (
      <>
        {" "}
        <span className="is-mobile is-hidden-tablet is-hidden-desktop">
          {" "}
          {dottedText(hit.location, 20)}
        </span>
        <span className="is-hidden-mobile">{dottedText(hit.location, 35)}</span>
      </>
    )
  }
  const removeNonBreakingSpace = text => {
    console.log({ text })
    if (text && text.length > 4) {
      if (text.includes("&nbsp;")) {
        console.log("found nbsp")
        return text.replace(/\&nbsp;/gi, " ")
        // console.log({ text })
        // return text
      }
    }
    return text
  }

  return (
    <React.Fragment>
      <ReactTooltip />
      <div
        className="designer-card-type3 designer-card-new"
        style={
          {
            // marginBottom: "15px",
            // borderBottom: "1px solid #fec600",
            // padding: "10px",
            // height: "180px",
          }
        }
        // onClick={params => {
        //   window.open(`/app/userprofiles/${hit.objectID}`, "_blank")
        // }}
      >
        <div
          className=""
          style={{ borderRadius: "10px", position: "relative" }}
        >
          <button
            className={`${
              onSelect
                ? "button is-light is-small"
                : "button is-warning is-small"
            }`}
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              border: "1px solid #fec600",
              // borderRadius: "10px",
            }}
            onClick={() => {
              window.open(`/app/userprofiles/${hit.objectID}`, "_blank")
            }}
          >
            View Profile
          </button>
          {hit.available === false || hit.available === undefined ? (
            <small
              className=""
              style={{
                fontSize: "12px",
                fontWeight: "400",
                color: "rgba(74,74,74,1)",
                //  textTransform: "uppercase",
                letterSpacing: "0.5px",
                position: "absolute",
                right: "10px",
                top: "50px",
                textAlign: "right",
              }}
            >
              <i
                className="fas fa-exclamation-triangle"
                style={{ color: "#fec600" }}
              ></i>{" "}
              On vacation <br></br> Unavailable for new jobs
            </small>
          ) : (
            ""
          )}
          {onSelect && (
            <div
              style={{
                position: "absolute",
                top: "50px",
                right: "10px",
                width: "146px",
              }}
            >
              <button
                onClick={() => {
                  if (onSelect) onSelect(hit)
                }}
                className="button is-warning is-small"
                style={{
                  // marginLeft: "10px",
                  letterSpacing: "0.5px",
                  // fontSize: "11px",
                  width: "100%",
                }}
              >
                Select Designer
              </button>
            </div>
          )}
          {/* <div className="card-image">
            <figure className="image is-3by1">
              <img
                style={{ borderRadius: "7px 7px 0px 0px" }}
                src="https://picsum.photos/id/1045/400/100"
                alt="Placeholder image"
              ></img>
            </figure>
          </div> */}
          <div className="card-content">
            <div className="media" style={{ marginBottom: "15px" }}>
              <div className="media-left" style={{ marginTop: "-100px" }}>
                <figure
                  // className="image is-128x128"
                  style={{
                    width: "148px",
                    height: "148px",
                    borderRadius: "50%",
                  }}
                  // style={{ padding: "2px" }}
                  onClick={params => {
                    // window.open(`/app/userprofiles/${hit.objectID}`, "_blank")
                  }}
                >
                  <LazyLoadImage
                    className=""
                    style={{
                      borderRadius: "50%",
                      objectFit: "cover",
                      height: "148px",
                      width: "148px",
                      padding: "2px",
                      border: "3px solid #fec600",
                      background: "white",
                    }}
                    src={hit.userProfileImage}
                    alt="Image"
                    effect="blur"
                  ></LazyLoadImage>
                  {/* <img></img> */}
                </figure>
              </div>
            </div>
            <div
              className="media"
              style={{
                marginBottom: "15px",
                border: "none",
                marginTop: "0px",
                paddingTop: "0px",
              }}
            >
              <div className="media-content">
                <a
                  className="designer_card_name"
                  style={{
                    // marginLeft: "10px",
                    // fontSize: "27px",
                    fontWeight: "500",
                    // textDecoration: "underline",
                    textDecorationColor: "#fec600",
                    color: "rgba(74,74,74,0.9)",
                    marginTop: "5px",
                    letterSpacing: "0.5px",
                  }}
                  onClick={() => {
                    window.open(`/app/userprofiles/${hit.objectID}`, "_blank")
                  }}
                >
                  {/* {hit.firstName} {hit.lastName} */}
                  {showName()}
                </a>{" "}
                <div>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "rgba(74,74,74,0.8)",
                      // marginTop: "5px",
                      marginBottom: "0px",
                    }}
                  >
                    <i
                      style={{ marginRight: "5px", color: "#fec600" }}
                      className="fas fa-map-marker-alt"
                    ></i>
                    {showLocation()}
                  </span>
                  <small
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "rgba(74,74,74,0.8)",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {hit.reviewCount === 0 ? (
                      // <i>- No reviews yet -</i>
                      ""
                    ) : (
                      <>
                        <span style={{ margin: "0px 10px", color: "#fec600" }}>
                          |
                        </span>
                        <span
                          style={{
                            // marginLeft: "10px",
                            marginRight: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {hit.reviewRating}{" "}
                          <i
                            style={{
                              // marginLeft: "7px",
                              marginRight: "5px",
                              fontSize: "12px",
                              color: "#fac600",
                            }}
                            className="fas fa-star"
                          ></i>
                          <span
                            style={{
                              fontSize: "12px",
                              letterSpacing: "0.5px",
                              // textTransform: "uppercase",
                              color: "rgba(74,74,74,0.8)",
                            }}
                          >
                            {hit.reviewCount} Review(s){" "}
                          </span>
                        </span>
                      </>
                    )}
                  </small>{" "}
                </div>
                {/* <p className="subtitle is-6">@johnsmith</p> */}
              </div>
            </div>
            <div
              className="content"
              style={{ marginBottom: "15px", lineHeight: "1.4" }}
            >
              <span
                className="profile-info-text"
                style={{
                  marginTop: "15px",
                  // marginLeft: "10px",
                  fontSize: "16px",
                  // fontWeight: "500",
                  // display: "flex",
                  // flexDirection: "column",
                }}
              >
                <span className="profile-short-text">
                  {ReactHtmlParser(
                    removeNonBreakingSpace(
                      dottedText(hit.aboutIntroduction, 120)
                    ),
                    {
                      decodeEntities: false,
                      transform,
                    }
                  )}
                </span>
                <span
                  // style={{ marginLeft: "10px" }}
                  href={`/app/userprofiles/${hit.objectID}`}
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: "500",
                    color: "#fec600",
                  }}
                  onClick={() => {
                    window.open(`/app/userprofiles/${hit.objectID}`, "_blank")
                  }}
                >
                  {" "}
                  Read more
                </span>
              </span>
            </div>
            <div className="content" style={{ lineHeight: "1.4" }}>
              {/* <p>
                I am a sports fanatic, adrenaline junkie, history buff,
                off-roading navigator, whiskey connoisseur and a hiking trails
                guide.
              </p> */}

              {showSkills()}
            </div>
            <div className="content" style={{}}>
              {/* <p>
                I am a sports fanatic, adrenaline junkie, history buff,
                off-roading navigator, whiskey connoisseur and a hiking trails
                guide.
              </p> */}
              <p
                style={{
                  marginBottom: "5px",
                  fontWeight: "600",
                  textTransform: "sentenceCase",
                }}
              >
                Destination Expert:
              </p>
              {showCountries()}
            </div>

            <div
              className="content"
              style={{ height: "72px", display: "none" }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "12px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "151px",
                }}
              >
                {showSkills()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="designer-card">
        <div className="designer-card-content">
          <div
            className="columns is-mobile"
            style={{ position: "relative", backgroundColor: "" }}
          >
            <div
              className="column is-3-desktop is-2-tablet is-3-mobile"
              style={{
                alignSelf: "center",
                // paddingLeft: "0px",
                // paddingTop: "0px",
                // paddingBottom: "0px",
              }}
            >
              <img
                className=""
                style={{
                  borderRadius: "5px",
                  width: "70%",
                  height: "70%",
                  // width: "56px",
                  // height: "56px",
                  objectFit: "cover",
                  // border: "0.5px solid #efefef",
                }}
                src={hit.userProfileImage}
                alt=""
              />
              {toggleBookmark ? (
                <i
                  className="fas fa-heart red-star"
                  style={{
                    float: "right",
                    position: "absolute",
                    top: "0px",
                    left: "5px",
                  }}
                  onClick={() => {
                    setToggleBookmark(!toggleBookmark)
                  }}
                ></i>
              ) : (
                <i
                  className="far fa-heart red-star"
                  style={{
                    float: "right",
                    position: "absolute",
                    top: "0px",
                    left: "5px",
                  }}
                  onClick={() => {
                    setToggleBookmark(!toggleBookmark)
                  }}
                ></i>
              )}
            </div>

            <div
              className="column is-9-desktop is-10-tablet is-9-mobile"
              style={{ alignSelf: "center" }}
            >
              <div
                className="columns is-mobile"
                style={{ position: "relative", backgroundColor: "" }}
              >
                <div
                  className="column is-8-desktop is-8-tablet  is-11-mobile"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    alignSelf: "flex-start",
                  }}
                >
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {hit.firstName} {hit.lastName}{" "}
                    {showHeart()}
                  </p>
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    {hit.location}
                  </p>

                  <div style={{ fontSize: "16px", marginTop: "5px" }}>
                    {hit.reviewRating}{" "}
                    <i
                      style={{ marginRight: "5px", fontSize: "16px" }}
                      className="fas fa-star red-star"
                    ></i>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        display: "inline",
                      }}
                    >
                      {" "}
                      {hit.reviewCount} reviews
                    </p>
                  </div>
                </div>
                <div
                  className="column is-4-desktop is-4-tablet is-1-mobile"
                  style={{
                    alignSelf: "flex-end",
                    paddingRight: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <button
                    onClick={() => {
                      if (onSelect) onSelect(hit)
                    }}
                    className="button is-warning is-pulled-right"
                  >
                    Select
                  </button>
                  <Link
                    to={`/app/userprofiles/${hit.objectID}`}
                    className="hover-link is-pulled-right is-small"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    View Profile
                  </Link>
                  <a
                    href={`/app/userprofiles/${hit.objectID}`}
                    className="hover-link is-pulled-right is-small"
                    target="_blank"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    View Profile
                  </a>
                </div>
              </div>

              <div
                className="columns is-mobile"
                style={{ position: "relative" }}
              >
                {" "}
                <div
                  className="column is-8-desktop is-8-tablet is-hidden-mobile"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    alignSelf: "flex-start",
                    paddingTop: "0px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {dottedText(hit.aboutIntroduction, 75)}
                    <a
                      href={`/app/userprofiles/${hit.objectID}`}
                      target="_blank"
                    >
                      {" "}
                      See more
                    </a>
                  </p>
                </div>
                <div
                  className="column is-hidden-desktop is-hidden-tablet is-hidden-mobile"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    alignSelf: "flex-start",
                    paddingTop: "0px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {dottedText(hit.aboutIntroduction, 35)}
                    <a
                      href={`/app/userprofiles/${hit.objectID}`}
                      target="_blank"
                    >
                      {" "}
                      See more
                    </a>
                  </p>
                </div>
                <div
                  className="column is-4-desktop is-4-tablet is-hidden-mobile"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    alignSelf: "center",
                  }}
                >
                  <a
                    onClick={() => {
                      window.open(
                        `/app/userprofiles/${hit.objectID}`,
                        "_blank"
                      )
                    }}
                    className="hover-link is-warning is-pulled-right is-small"
                  >
                    View Profile
                  </a>
                </div>
                <div
                  className="column is-hidden-desktop is-hidden-tablet is-12-mobile"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    alignSelf: "flex-start",
                  }}
                >
                  <a
                    onClick={() => {
                      window.open(
                        `/app/userprofiles/${hit.objectID}`,
                        "_blank"
                      )
                    }}
                    className="hover-link is-warning is-pulled-right is-small"
                    style={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    View Profile
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  )
}

export default DesignerCardNew
