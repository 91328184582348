import React, { createContext } from "react"
import { useImmerReducer } from "use-immer"
import { profileStateReducer } from "./profile-state-reducer"

const defaultProfileState = {
  publicProfile: {
    username: "",
    firstName: "",
    lastName: "",
    gender: "",
    location: "",
    publicTwitterLink: "",
    publicInstagramLink: "",
    publicFacebookLink: "",
    profilePhotos: [],
    reviewRating: 0,
    reviewCount: 0,
    reviewTotal: 0,
    about: "",
    aboutIntroduction: "",
    aboutSkills: "",
    countriesVisited: [],
    countriesVisitedCount: "",
    tripsDesigned: 0,
    tags: [],
    reviews: [
      {
        uid: "",
        firstName: "",
        reviewDate: "",
        rating: 0,
        review: "",
        reviewerProfileImage: "",
        response: {
          uid: "",
          firstName: "",
          responseDate: "",
          response: "",
          userProfileImage: "",
        },
      },
    ],
    userProfileImage: "",
  },
  tempPublicProfile: {
    username: "",
    firstName: "",
    lastName: "",
    gender: "",
    location: "",
    publicTwitterLink: "",
    publicInstagramLink: "",
    publicFacebookLink: "",
    profilePhotos: [],
    reviewRating: 0,
    reviewCount: 0,
    reviewTotal: 0,
    about: "",
    aboutIntroduction: "",
    aboutSkills: "",
    countriesVisited: [],
    countriesVisitedCount: "",
    tripsDesigned: 0,
    tags: [],
    reviews: [
      {
        uid: "",
        firstName: "",
        reviewDate: "",
        rating: 0,
        review: "",
        reviewerProfileImage: "",
        response: {
          uid: "",
          firstName: "",
          responseDate: "",
          response: "",
          userProfileImage: "",
        },
      },
    ],
    userProfileImage: "",
  },
  userProfile: {
    username: "",
    firstName: "",
    lastName: "",
    gender: "",
    location: "",
    publicTwitterLink: "",
    publicInstagramLink: "",
    publicFacebookLink: "",
    reviewRating: 0,
    reviewCount: 0,
    reviewTotal: 0,
    about: "",
    tags: [],
    reviews: [
      {
        uid: "",
        firstName: "",
        reviewDate: "",
        rating: 0,
        review: "",
        reviewerProfileImage: "",
        response: {
          uid: "",
          firstName: "",
          responseDate: "",
          response: "",
          userProfileImage: "",
        },
      },
    ],
    userProfileImage: "",
  },
  privateProfile: "",
  tempPrivateProfile: "",
  userProfileImageBlob: "",
  editMode: false,
  dbUpdateRequired: false,
  designerList: [],
}

const profileStateContext = createContext(defaultProfileState)
const dispatchProfileStateContext = createContext(undefined)

export const useProfileStateStore = () => [
  React.useContext(profileStateContext),
  React.useContext(dispatchProfileStateContext),
]

export const ProfileStateProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(
    profileStateReducer,
    defaultProfileState
  )
  return (
    <profileStateContext.Provider value={state}>
      <dispatchProfileStateContext.Provider value={dispatch}>
        {children}
      </dispatchProfileStateContext.Provider>
    </profileStateContext.Provider>
  )
}
