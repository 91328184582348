import { navigate } from "gatsby"
export function profileStateReducer(draft, action) {
  switch (action.type) {
    case "updateDesignerList":
      var uid = { uid: action.dId }
      draft.designerList.push({ ...action.data, ...uid })
      return

    case "replyDBUpdate":
      draft.requiresReplyDBUpdate = action.data

      return
    case "updateReviewList":
      if (draft.reviews == undefined) draft.reviews = []
      draft.reviews.push(action.data)
      return
    case "saveReply":
      draft.tempPublicProfile.reviews[action.index].response.firstName =
        action.firstName
      draft.tempPublicProfile.reviews[action.index].response.userProfileImage =
        action.userProfileImage
      draft.tempPublicProfile.reviews[action.index].response.uid = action.uid
      draft.tempPublicProfile.reviews[
        action.index
      ].response.responseDate = new Date()

      draft.publicProfile.reviews[action.index].response =
        draft.tempPublicProfile.reviews[action.index].response
      draft.requiresReplyDBUpdate = true
      return
    case "updateReply":
      // console.log({ action })
      // console.log(draft.tempPublicProfile.reviews)

      // if (draft.tempPublicProfile.reviews[action.index].response == undefined) {
      //   draft.tempPublicProfile.reviews[action.index].response = {}
      // }
      // draft.tempPublicProfile.reviews[action.index].response.response =
      //   action.data

      var itemIndex = draft.reviews.findIndex(
        item => item.reviewId === action.data.reviewId
      )
      if (itemIndex !== -1) {
        draft.reviews[itemIndex] = action.data
      }
      return

    case "updateTempProfileImageUrl":
      draft.tempPrivateProfile.userProfileImage = action.data
      return

    case "saveEditedPrivateProfileChanges":
      return
    case "updateUserState":
      draft.privateProfile = action.data
      draft.tempPrivateProfile = action.data
      return
    case "updateTempPrivateProfileFirstName":
      draft.tempPrivateProfile.firstName = action.data
      return
    case "updateTempPrivateProfileLastName":
      draft.tempPrivateProfile.lastName = action.data
      return
    case "updateTempPrivateProfileAAddressLine1":
      draft.tempPrivateProfile.homeAddress.line1 = action.data
      return

    case "updateTempPrivateProfileAAddressLine2":
      draft.tempPrivateProfile.homeAddress.line2 = action.data
      return
    case "updateTempPrivateProfileAAddressCity":
      draft.tempPrivateProfile.homeAddress.city = action.data
      return
    case "updateTempPrivateProfileAAddressState":
      draft.tempPrivateProfile.homeAddress.state = action.data
      return
    case "updateTempPrivateProfileAAddressZip":
      draft.tempPrivateProfile.homeAddress.zip = action.data
      return

    case "updateTempPrivateProfileAAddressCountry":
      draft.tempPrivateProfile.homeAddress.country = action.data
      return

    case "updateTempPrivateProfileBirthday":
      draft.tempPrivateProfile.dob = action.data
      return
    // Public profile reducers
    case "setDBUpdateRequired":
      draft.dbUpdateRequired = action.data
      return
    case "updateAboutIntroduction":
      draft.tempPublicProfile.aboutIntroduction = action.data
      return
    case "updateAboutSkills":
      draft.tempPublicProfile.aboutSkills = action.data
      return
    case "updateCountriesTraveled":
      draft.tempPublicProfile.countriesVisited = action.data
      draft.tempPublicProfile.countriesVisitedCount =
        draft.tempPublicProfile.countriesVisited.length
      return
    case "updateDesignerSkills":
      draft.tempPublicProfile.skills = action.data
      return
    case "updateLanguagesSpoken":
      draft.tempPublicProfile.languagesSpoken = action.data
      return
    case "updateCountriesForItineraries":
      draft.tempPublicProfile.countriesForItineraries = action.data
      return
    case "updateDisplayBlogPostOnProfile":
      draft.tempPublicProfile.displayBlogPostsOnProfilePage = action.data
      return
    case "updateBlogPostURLValue":
      if (draft.tempPublicProfile.blogPostURL === undefined) {
        draft.tempPublicProfile.blogPostURL = []
      }
      draft.tempPublicProfile.blogPostURL[action.index] = action.data
      return
    case "deleteTempProfilePhoto":
      var itemIndex = draft.publicProfile.profilePhotos.findIndex(
        item => item.name === action.data
      )
      if (itemIndex !== -1) {
        draft.publicProfile.profilePhotos.splice(itemIndex, 1)
        draft.dbUpdateRequired = true
      }
      return
    case "addNewProfileImage":
      if (draft.publicProfile.profilePhotos == undefined) {
        draft.publicProfile.profilePhotos = []
      }
      draft.publicProfile.profilePhotos.push(action.data)
      draft.dbUpdateRequired = true
      // draft.profilePhotosLocal={}
      // draft.profilePhotosLocal = action.data
      return
    case "updateProfileImageCaption":
      if (draft.publicProfile.profilePhotos == undefined) {
        return
      }
      if (action.index === -1) return
      draft.publicProfile.profilePhotos[action.index].caption = action.data
      draft.dbUpdateRequired = true
      // draft.profilePhotosLocal={}
      // draft.profilePhotosLocal = action.data
      return
    case "updateInitialState":
      draft.publicProfile = action.data
      draft.tempPublicProfile = action.data
      return
    case "updateUserProfile":
      draft.userProfile = action.data
      return
    case "updateUserImageBlob":
      draft.userProfileImageBlob = action.data
      return
    case "toggleEditMode":
      draft.editMode = !draft.editMode
      return
    case "cancelEditMode":
      draft.editMode = false
      draft.tempPublicProfile = draft.publicProfile
      return
    case "updateFirstName":
      draft.tempPublicProfile.firstName = action.data
      return
    case "updateAvailability":
      draft.publicProfile.available = action.data
      return
    case "updateBackInDays":
      draft.publicProfile.backInDays = action.data
      return
    case "updateLastName":
      draft.tempPublicProfile.lastName = action.data
      return
    case "updateAboutField":
      draft.tempPublicProfile.about = action.data
      return
    case "saveEditedChanges":
      draft.publicProfile = draft.tempPublicProfile
      draft.editMode = false
      if (action.context) {
        action.context
          .firestore()
          .collection("users")
          .doc(action.uid)
          .collection("publicProfile")
          .doc(action.uid)
          .update(draft.publicProfile)
          .then(function() {
            console.log("Document successfully updated!")
          })
          .catch(error => {
            console.log("Error updating the document:", error)
          })
      }
      window.scrollTo(0, 0)
      return
    case "updateProfileImageUrl":
      draft.publicProfile.userProfileImage = action.data
      draft.tempPublicProfile.userProfileImage = action.data
      var _data = { userProfileImage: action.data }
      if (action.context) {
        action.context
          .firestore()
          .collection("users")
          .doc(action.uid)
          .collection("publicProfile")
          .doc(action.uid)
          .update(_data)
          .then(function() {
            console.log("Document successfully updated!")
          })
          .catch(error => {
            console.log("Error updating the document:", error)
          })

        action.context
          .firestore()
          .collection("users")
          .doc(action.uid)
          .update(_data)
          .then(function() {
            console.log("Document successfully updated!")
          })
          .catch(error => {
            console.log("Error updating the document:", error)
          })
      }

      return
    case "updateTags":
      let _darray = []
      if (action.data.value) {
        Object.values(action.data.value).map((val, index) => {
          if (val !== undefined) _darray.push(val.label)
        })
        draft.tempPublicProfile.tags = _darray
      }
      return

    default:
      return
  }
}
