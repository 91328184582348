import React from "react"
import "./StarRating.scss"
const StarRating = ({ score, reviewCount }) => {
  return (
    <>
      <div
        style={{
          fontSize: "16px",
          marginBottom: "5px",
          marginTop: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // align="center"
        className="content"
      >
        <i style={{ marginRight: "4px" }} className="fas fa-star red-star"></i>

        {score ? (
          <>
            <span style={{ fontWeight: "400", marginRight: "7px" }}>
              {score}
            </span>
            <div
              className="tags has-addons "
              style={{
                // marginTop: "5px",
                marginLeft: "5px",
                display: "inline",
                textTransform: "uppercase",
                // fontWeight: "600",
                letterSpacing: "0.5px",
                fontSize: "10px",
              }}
            >
              <span className="tag is-warning is-small">{reviewCount}</span>
              <span className="tag is-small">Reviews</span>
            </div>
          </>
        ) : (
          "No reviews"
        )}
      </div>
    </>
  )
}

export default StarRating
