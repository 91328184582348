import React, { useState, useEffect } from "react"
// import { VectorMap } from "react-jvectormap"
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps"
import { countryCode } from "../../constants/data/general"
import { geoPatterson } from "d3-geo-projection"

const width = 800
const height = 600
const projection = geoPatterson()
  .translate([width / 2, height / 2])
  .scale(120)

// const mapData = {
//   CN: 100000,
//   IN: 9900,
//   SA: 86,
//   EG: 70,
//   SE: 0,
//   FI: 0,
//   FR: 0,
//   US: 20,
//   AU: 10,
// }
const handleClick = (e, countryCode) => {
  console.log(countryCode)
}
const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json"

const Map = React.memo(({ mapData }) => {
  let countries = []
  if (mapData !== undefined) {
    try {
      mapData.map((element, item) => {
        const _foundItem = countryCode.find(
          s => s.Name === element.label.trim()
        )
        console.log(element, _foundItem)
        if (_foundItem !== -1) countries.push(_foundItem.Code)
      })
    } catch (err) {
      console.log(err)
    }
  }
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 })
  const [tooltipContent, setTooltipContent] = useState(" ")
  console.log(countries)
  // useEffect(() => {
  //   setPosition({ coordinates: [0, 0], zoom: 1 })
  // }, [])

  function handleZoomIn() {
    console.log("zoom in")
    if (position.zoom >= 4) return
    setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }))
  }

  function handleZoomOut() {
    console.log("zoom out")
    if (position.zoom <= 1) return
    setPosition(pos => ({ ...pos, zoom: pos.zoom / 2 }))
  }

  function handleMoveEnd(position) {
    setPosition(position)
  }
  function handleCenter() {
    setPosition({ coordinates: [0, 0], zoom: 1 })
  }

  return (
    <div>
      {/* <VectorMap
        map={"world_mill"}
        backgroundColor="transparent" //change it to ocean blue: #0077be
        zoomOnScroll={false}
        containerStyle={{
          width: "100%",
          height: "520px",
        }}
        onRegionClick={handleClick} //gets the country code
        containerClassName="map"
        regionStyle={{
          initial: {
            fill: "#fff6d7",
            "fill-opacity": 0.9,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 0,
          },
          hover: {
            "fill-opacity": 0.8,
            cursor: "pointer",
          },
          selected: {
            fill: "#2938bc", //color for the clicked country
          },
          selectedHover: {},
        }}
        regionsSelectable={false}
        series={{
          regions: [
            {
              values: mapData ? mapData : [], //this is your data
              scale: ["#Fec600"], //your color game's here
              normalizeFunction: "polynomial",
            },
          ],
        }}
      /> */}
      <div
        style={{
          zIndex: "99",
          height: "20px",
          fontSize: "16px",
          fontWeight: "600",
          textTransform: "uppercase",
          letterSpacing: "0.5px",
        }}
      >
        {tooltipContent}
      </div>
      <ComposableMap width={width} height={height} projection={projection}>
        <ZoomableGroup
          zoom={position.zoom}
          center={position.coordinates}
          onMoveEnd={handleMoveEnd}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => {
                // console.log({ geo })
                const d = countries.find(s => s === geo.properties["Alpha-2"])
                // console.log(d)
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={d ? "#FEC600" : "#F5F4F6"}
                    stroke={d ? "#D6D6DA" : "#D6D6DA"}
                    onMouseEnter={() => {
                      const { name } = geo.properties
                      setTooltipContent(`${name}`)
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("")
                    }}
                    style={{
                      default: {
                        fill: geo.properties.CONTINENT === "#FFFFFF",
                        stroke: geo.properties.CONTINENT === "#ffffff",
                        strokeWidth: 0.75,
                        outline: "#4a4a4a",
                        transition: "all 250ms",
                      },
                      hover: {
                        // fill: "#FF6F61",
                        fill: "#4a4a4a",
                        // stroke: "#4a4a4a",
                        strokeWidth: 0.75,
                        outline: "none",
                        transition: "all 250ms",
                      },
                      pressed: {
                        // fill: "#DD4132",
                        fill: "#fec600",
                        fill: "#",
                        stroke: "#9E1030",
                        strokeWidth: 0.75,
                        outline: "none",
                        transition: "all 250ms",
                      },
                    }}
                  />
                )
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <div
        className="controls"
        style={{
          // maxWidth: "240px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button
          className="button is-small is-warning"
          style={{
            margin: "5px",
            fontWeight: "400",
            fontSize: "14px",
            width: "90px !important",
          }}
          onClick={handleZoomIn}
        >
          Zoom In
        </button>
        <button
          className="button is-small is-warning"
          style={{
            margin: "5px",
            fontWeight: "400",
            fontSize: "14px",
            width: "90px !important",
          }}
          onClick={handleCenter}
        >
          Center
        </button>
        <button
          className="button is-small is-warning"
          style={{
            margin: "5px",
            fontWeight: "400",
            fontSize: "14px",
            width: "90px !important",
          }}
          onClick={handleZoomOut}
        >
          Zoom Out
        </button>
      </div>
    </div>
  )
})
export default Map
